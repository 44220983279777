import React, { useEffect, useState } from 'react';
import { func, string } from 'prop-types';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { Input } from '@thd-olt-component-react/input';
import { usePresentation } from '../../../../context/PresentationProvider';

export const ProductReviewField = ({ formInputClasses, reviewText, setReviewText }) => {
  const { useCondensedLayout } = usePresentation();
  const [errorText, setErrorText] = useState(null);
  const [charactersLeft, setCharactersLeft] = useState(50);
  const minimumCharacters = 50;
  const maximumCharacters = 500;
  const guidelinesContent = [
    ['For Shipping / Damaged / Delivery item issue, click on Feedback Tab, click on Website Feedback, ',
      'click on Feedback topic, click on Shipping/Delivery, add your Feedback, or contact us at 1-800-430-3376, ',
      'do not post on product review'].join(''),
    'What features stand out most?',
    'What do you like or dislike about it?',
    'How long have you had it?',
    'Does it meet your expectations?'
  ];
  const guideLineList = (
    <ul>
      {guidelinesContent.map((content) => <li key={content}>{content}</li>)}
    </ul>
  );

  const invalidReviewLength = (review) => {
    return !(review.trim().length > maximumCharacters
      || review.trim().length < minimumCharacters);
  };

  useEffect(() => {
    const characterCount = reviewText.length;
    if (characterCount < minimumCharacters) {
      setErrorText('Review must be a minimum of 50 characters');
    } else if (characterCount > maximumCharacters) {
      setErrorText('Review must be a maximum of 500 characters');
    } else {
      setErrorText(null);
    }
    setCharactersLeft(minimumCharacters - characterCount);
  }, [reviewText]);

  return (
    <Row
      className={formInputClasses}
    >
      <Col
        className={`write-a-review-content__review-text ${formInputClasses}`}
      >
        <h3>Your Review</h3>
        <Row className="flush">
          <Col xs="9">
            <Input
              className="write-review-content__review-text__textarea"
              errorText={errorText}
              onChange={(productReview) => setReviewText(productReview)}
              placeholder="Example: This drill was easy to assemble, lightweight, and quiet.
                             I'm really pleased with the battery life and was able to use the drill
                             for over an hour of heavy use without having to charge it..."
              scrollOnFocus
              type="textarea"
              validation={(review) => invalidReviewLength(review)}
              value={reviewText}
            />
          </Col>
          <Col xs="3">
            <div className="write-review-content__review-text__guidelines">
              <h2>Review Guidelines</h2>
              <div className="write-review-content__review-text__guidelines__content">
                <p>Focus on the product and your individual experience with it:</p>
                {guideLineList}
              </div>
            </div>
          </Col>
        </Row>
        <div className="write-review-content__review-text__chars">
          {useCondensedLayout && charactersLeft > 0 && charactersLeft !== 50
            && <p>Required: {charactersLeft}</p>}
        </div>
      </Col>
    </Row>
  );
};

ProductReviewField.displayName = 'ProductReviewField';

ProductReviewField.propTypes = {
  formInputClasses: string,
  reviewText: string,
  setReviewText: func.isRequired
};

ProductReviewField.defaultProps = {
  formInputClasses: null,
  reviewText: ''
};

import React, { useContext, useReducer, useRef } from 'react';
import { func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Button } from '@thd-olt-component-react/button';
import { Image } from '@thd-olt-component-react/core-ui';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { DeviceDetection } from '../../../DeviceDetection/DeviceDetection.component';
import { ProductReviewTitleField } from './ProductReviewTitleField';
import { ProductReviewField } from './ProductReviewField';
import { ImageUpload } from './ImageUpload';
import { UserDetails } from './UserDetails';
import { FormFooter } from './FormFooter';
import { ProductRatingDetails } from './ProductRatingDetails';
import * as api from '../api';
import { track } from '../../../../ratings-reviews-analytics';
import { usePresentation } from '../../../../context/PresentationProvider';

const initialState = {
  deviceFingerprint: '',
  displayName: '',
  email: '',
  errorHeading: null,
  errorMessage: null,
  formHeader: null,
  images: [],
  ratings: [],
  recommend: null,
  reviewSubmitted: false,
  reviewText: '',
  reviewTitle: ''
};

function reducer(state, { action, value }) {
  switch (action) {
  case 'deviceFingerprint':
    return { ...state, deviceFingerprint: value };
  case 'displayName':
    return { ...state, displayName: value };
  case 'email':
    return { ...state, email: value };
  case 'errorHeading':
    return { ...state, errorHeading: value };
  case 'errorMessage':
    return { ...state, errorMessage: value };
  case 'formHeader':
    return { ...state, formHeader: value };
  case 'images':
    return { ...state, images: value };
  case 'ratings':
    return { ...state, ratings: value };
  case 'recommend':
    return { ...state, recommend: value };
  case 'reviewSubmitted':
    return { ...state, reviewSubmitted: value };
  case 'reviewText':
    return { ...state, reviewText: value };
  case 'reviewTitle':
    return { ...state, reviewTitle: value };
  default:
    return state;
  }
}

export const WriteAReviewForm = ({
  apiKey, closeOverlay, itemId, product, headerText, reviewStatistics
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const formRef = useRef(null);
  const { isServer, hosts } = useContext(ExperienceContext);
  const { useCondensedLayout } = usePresentation();
  const serverHost = (hosts || {}).api;
  const hostName = isServer ? serverHost : '';
  const {
    deviceFingerprint,
    displayName,
    email,
    errorHeading,
    errorMessage,
    formHeader,
    images,
    ratings,
    recommend,
    reviewSubmitted,
    reviewText,
    reviewTitle
  } = state;
  let formHeaderText = formHeader || headerText;
  const onSubmit = async () => {
    const secondaryRatings = ratings.filter((rating) => rating.name !== 'Overall');
    const overallRating = ratings.find((rating) => rating.name === 'Overall');
    const review = {
      title: reviewTitle,
      text: reviewText,
      userNickName: displayName,
      userEmail: email,
      isRecommended: recommend,
      rating: overallRating ? overallRating.value : '',
      sendEmailAlertWhenPublished: 'yes',
      fp: deviceFingerprint,
      action: 'submit'
    };

    // BV only requires caption and url for review photos
    if (images.length) {
      review.photos = images.map(({ caption, url }) => ({ caption, url }));
    }

    // map secondary ratings for BV :eyeroll:
    secondaryRatings.forEach((rating) => {
      const { key, value } = rating;
      review[`rating_${key}`] = value ? value.toString() : null;
    }
    );
    review.secondaryRatings = secondaryRatings || [];
    // if (bvUserId) {
    //   review.userId = bvUserId;
    // }

    const response = await api.submitReview(review, itemId, hostName, apiKey, 'submit');
    const { status = 0 } = response || {};
    if (status === 200) {
      dispatch({ action: 'formHeader', value: 'Thanks for sharing your thoughts!' });
      dispatch({ action: 'reviewSubmitted', value: true });
    } else if (status !== 200) {
      formRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
      const simpleResponse = response && typeof response === 'string';
      dispatch({ action: 'errorMessage', value: simpleResponse ? response.replace(/"/g, '') : null });
      dispatch({
        action: 'errorHeading',
        value: simpleResponse
          ? 'Please complete the following required field(s):'
          : 'An error occurred. Please try your request again later.'
      });
    }
    track('event', {
      origin: 'ratings and reviews',
      location: 'writereviewoverlay',
      eventType: 'click',
      value: 'submit'
    });
  };

  const onCloseOverlay = () => {
    closeOverlay();
    dispatch({ action: 'email', value: '' });
    dispatch({ action: 'images', value: [] });
    dispatch({ action: 'ratings', value: [] });
    dispatch({ action: 'recommend', value: null });
    dispatch({ action: 'reviewText', value: '' });
    dispatch({ action: 'formHeader', value: null });
    dispatch({ action: 'displayName', value: '' });
    dispatch({ action: 'reviewTitle', value: '' });
    dispatch({ action: 'errorMessage', value: null });
    dispatch({ action: 'errorHeading', value: null });
    dispatch({ action: 'reviewSubmitted', value: false });
  };
  if (!product) {
    return null;
  }

  const { identifiers, media } = product || {};
  const { brandName = '', productLabel = '' } = identifiers || {};
  let productImage = (media || {}).image;

  const formInputClasses = classNames({
    'write-review-content__no-padding': useCondensedLayout
  });

  return (
    <>
      {!useCondensedLayout
        && (
          <div className="write-review-content__header">
            <h2 className="write-review-content__title-header">{formHeaderText}</h2>
            <button aria-label="Close" onClick={onCloseOverlay} type="button">
              <Image src="https://assets.homedepot-static.com/images/v1/close-symbol-orange.svg" alt="Close" />
            </button>
          </div>
        )}
      {reviewSubmitted ? (
        <div className="write-review-content__submit__review">
          <span className="write-review-content__submit__thank-you-screen__mobile__content">
            {/* eslint-disable-next-line max-len */}
            Your review has been submitted successfully. Please note that it may take up to 48 hours to be displayed on homedepot.com
          </span>
          <Button className="write-review-content__submit__close" onClick={onCloseOverlay}>Continue</Button>
        </div>
      ) : (
        <div className="write-review-content" ref={formRef}>
          <DeviceDetection
            onSuccess={(fingerprint) => dispatch({ action: 'deviceFingerprint', value: fingerprint })}
          />
          <ProductRatingDetails
            brandName={brandName}
            className={formInputClasses}
            error={{ heading: errorHeading, message: errorMessage }}
            imageUrl={productImage.url}
            productLabel={productLabel}
            onRecommendationChange={(recommends) => dispatch({ action: 'recommend', value: recommends ? 'yes' : 'no' })}
            onRatingChange={(newRatings) => dispatch({ action: 'ratings', value: newRatings })}
            reviewStatistics={reviewStatistics}
          />
          {/* ReviewTitle */}
          <ProductReviewTitleField
            formInputClasses={formInputClasses}
            reviewTitle={reviewTitle}
            setReviewTitle={(text) => dispatch({ action: 'reviewTitle', value: text })}
          />
          {/* ReviewDescription */}
          <ProductReviewField
            formInputClasses={formInputClasses}
            reviewText={reviewText}
            setReviewText={(text) => dispatch({ action: 'reviewText', value: text })}
          />
          {/* UploadImage */}
          <ImageUpload
            className={formInputClasses}
            images={images}
            setImages={(newImages) => dispatch({ action: 'images', value: newImages })}
          />
          <UserDetails
            email={email}
            displayName={displayName}
            onDisplayNameChange={(newDisplayName) => dispatch({ action: 'displayName', value: newDisplayName })}
            onEmailChange={(newEmail) => dispatch({ action: 'email', value: newEmail })}
          />
          <FormFooter onSubmit={onSubmit} />
        </div>
      )}
    </>
  );
};

WriteAReviewForm.displayName = 'WriteAReviewForm';

WriteAReviewForm.propTypes = {
  // Callback to close the overlay the form is shown in
  closeOverlay: func,
  headerText: string,
  itemId: string.isRequired,
  apiKey: string.isRequired,
  product: shape({}),
  reviewStatistics: shape({}),
};

WriteAReviewForm.defaultProps = {
  closeOverlay: null,
  headerText: 'Write A Review',
  product: null,
  reviewStatistics: null,
};
